import React from 'react'
import PropTypes from 'prop-types'

import locationUtils from 'utils/common/location'
import I18n from 'i18n/i18n'

// COMPONENTS
import MyLocations from './contact/MyLocations'
import InputPhoneNumber from './InputPhoneNumber'
// CONSTANTS
import {
  EMAIL_EXPRESS, OPTIONAL_NOTE
} from 'constants/imageConstants'
import { COUNTRY_ID } from 'constants/dashBoardAnalyticsConstants'
import { SOURCE_GOOGLE } from 'constants/common/locationConstants'
import { LIMIT_NOTES_CHAR } from 'constants/common/popupConstants'
import { isEmpty, isUndefined, size } from 'lodash'
import AddressInput from './AddressInput'
import clsx from 'utils/common/clsx'
import * as eventTracking from 'utils/trackingMoengage'

class CPOD extends React.Component {
  static disabledAddressAutoFill(address, verifyEnableDocument) {
    return !verifyEnableDocument || (I18n.language.toUpperCase() === COUNTRY_ID) || !address
  }

  static validErrorKeys(invalidKeys, key) {
    if (size(invalidKeys) === 0) {
      return false
    }

    return invalidKeys.includes(key)
  }

  constructor(props) {
    super(props)
    this.state = {
      isShowNoteField: false,
      maxLength: LIMIT_NOTES_CHAR,
      remainChars: LIMIT_NOTES_CHAR,
      errorMessage: '',
    }
    this.location = null
    this.handleCustomerPhoneChange = this.handleCustomerPhoneChange.bind(this)
    this.handleCustomerPhoneBlur = this.handleCustomerPhoneBlur.bind(this)
    this.handleCustomerPhoneFocus = this.handleCustomerPhoneFocus.bind(this)
    this.handleRef = this.handleRef.bind(this)
  }

  componentDidMount() {
    const { documentReturn } = this.props
    const { maxLength } = this.state
    this.setState({ remainChars: documentReturn.notes ? maxLength - documentReturn.notes.length : maxLength })
  }

  componentDidUpdate() {
    const { address } = this.props
    if ((!this.location && address)
      || (this.location && this.location.formatted_address !== address)
    ) {
      this.checkAddressToValidLazy()
    }
  }
  
  checkAddressToValidLazy() {
    const {
      address,
      handleUpdateAddressComponentForDocumentReturn,
      extraInfos
    } = this.props

    const addressComponents = this.location?.address_components
    if (addressComponents?.length > 0) {
      this.handleValidateLazyAddress(addressComponents)
      return
    }

    locationUtils.handleCallGeocodeAPI({ address }, (results) => {
      if(!results) return
      const { addressComponent } = locationUtils.getDataResponse(results[0])
      if (this.location) {
        this.location.address_components = addressComponent
      } else {
        this.location = {
          address_components: addressComponent
        }
      }
      this.handleValidateLazyAddress(addressComponent)
      // update address component for document return
      if (handleUpdateAddressComponentForDocumentReturn) {
        handleUpdateAddressComponentForDocumentReturn(addressComponent)
      }
    }, extraInfos)
  }

  handleSetError = (error) => {
    const { errorMessage } = this.state
    if (isEmpty(errorMessage)) {
      this.setState({ errorMessage: error })
    }
  }

  handleValidateLazyAddress = async (address) => {
    const {
      extraInfos,
      setLocation: currentLocation,
    } = this.props
    const isValid = await locationUtils.validateLazyAddress(
      { address_components: address },
      extraInfos, this.inneraddress_1?.closest('.Input'), '', null, (errorMessage) => { this.handleSetError(errorMessage) }
    )
    if (isValid) {
      const { errorMessage } = this.state
      this.inneraddress_1?.closest('.Input')?.classList?.remove('error')
      const validateLocationNode = document.querySelector(`#validateLocationCODPOD-${currentLocation ? currentLocation.temp_id : ''} .Input`)
      if (validateLocationNode) {
        validateLocationNode.classList.remove('error')
      }
      if (errorMessage) this.setState({ errorMessage: '' })
    }
  }

  handleSetPlace = (place) => {
    const {
      handleAddress,
      handleMappingAddress,
      extraInfos,
      setLocation: currentLocation
    } = this.props
    this.location = place
    this.inneraddress_1?.closest('.Input')?.classList?.remove('error')
    const validateLocationNode = document.getElementById(`validateLocationCODPOD-${currentLocation.temp_id}`)
    if (validateLocationNode) {
      validateLocationNode.querySelector('.Input').classList.remove('error')
    }
    this.setState({ errorMessage: '' })
    handleMappingAddress(place.address_components, {
      city: 'administrative_area_level_2',
      state: 'administrative_area_level_1',
      postal_code: 'postal_code',
    })
    handleAddress({
      address: place,
      value: this.inneraddress_1.value,
    }, true)
    // validate lazy address
    locationUtils.validateLazyAddress(place, extraInfos,
      this.inneraddress_1?.closest('.Input'), '', null, (errorMessage) => { this.handleSetError(errorMessage) } )

    const isGoogle = place?.source === SOURCE_GOOGLE
    eventTracking.eventSetLocation({
      source: `Location search - ${isGoogle ? 'Google' : 'DB'}`,
      location: 'document return',
      coordinate: place?.location,
    })
  }

  handleCustomerPhoneChange(phone) {
    const { handlePhoneChange } = this.props
    handlePhoneChange(phone)
  }

  handleCustomerPhoneBlur(phone) {
    const { handlePhoneChange } = this.props
    handlePhoneChange(phone)
  }

  handleCustomerPhoneFocus(phone) {
    const { handlePhoneChange } = this.props
    handlePhoneChange(phone)
  }

  handleChangeMappingField(value, key) {
    const { handleChangeMappingField } = this.props

    if (!isUndefined(handleChangeMappingField)) {
      handleChangeMappingField([{
        long_name: value,
        short_name: value,
        types: [key],
      }])
    }
  }

  handleSetLocationFromContact(contact) {
    const { handleSelectContact, extraInfos } = this.props
    // validate lazy address
    locationUtils.validateLazyAddress(contact, extraInfos,
      this.inneraddress_1?.closest('.Input'), '', null, (errorMessage) => { this.handleSetError(errorMessage) })
    this.location = contact
    handleSelectContact(contact)
  }

  handleRef(ref, key) {
    const { onRef } = this.props
    if (ref) {
      onRef(ref, key)
      this[`inner${key}`] = ref
    }
  }

  handleChangePicName(e) {
    const { onchangePicName } = this.props || {}
    const picName = e.target.value
    if (typeof onchangePicName === 'function') onchangePicName(picName)
  }

  handleChangeNotes(e) {
    const { onchangeNotes } = this.props || {}
    const notes = e.target.value
    this.remainingCharacters(notes)
    if (typeof onchangeNotes === 'function') onchangeNotes(notes)
  }

  remainingCharacters(value) {
    const { maxLength } = this.state
    const charCount = value.length
    const remainChars = maxLength - charCount

    this.setState({ remainChars })
  }

  static renderTitle(existedCustomer) {
    return (
      <div className={`flex flex-start ${!existedCustomer ? 'mar10' : ''} pl5 pr5`}>
        <img src={EMAIL_EXPRESS} alt="" />
        <b className="ml10 default-font White-text">
          {I18n.t('webapp.new_booking.step_2.express_mail_document_to')}
        </b>
      </div>
    )
  }

  renderStateAndPostalCode = (documentReturnData, invalidKeys, disabledAddressAutoFill) => {
    const { onRef } = this.props
    return (
      <div className="mar10 flex">
        <div className="flex-three mr5 ml5">
          <div className={`Input ${CPOD.validErrorKeys(invalidKeys, 'state') ? 'select-error' : ''} pr10 pl10 ${disabledAddressAutoFill}`}>
            <input
              tabIndex={1}
              className="default-font w100 reset p"
              type="text"
              ref={ref => onRef(ref, 'state')}
              name="document_returns_state"
              placeholder={I18n.t('webapp.new_booking.step_2.state')}
              defaultValue={documentReturnData.state}
              onChange={e => this.handleChangeMappingField(e.target.value, 'administrative_area_level_1')}
            />
          </div>
        </div>
        <div className="flex-two mr5 ml5">
          <div className={`Input ${CPOD.validErrorKeys(invalidKeys, 'postal_code') ? 'select-error' : ''} pr10 pl10 ${disabledAddressAutoFill}`}>
            <input
              tabIndex={1}
              className="default-font w100 reset p"
              type="text"
              ref={ref => onRef(ref, 'postal_code')}
              name="document_returns_postal_code"
              placeholder={I18n.t('webapp.new_booking.step_2.postal_code')}
              defaultValue={documentReturnData.postal_code}
              onChange={e => this.handleChangeMappingField(e.target.value, 'postal_code')}
            />
          </div>
        </div>
      </div>
    )
  }

  renderNoteField = (notes, isShowText) => {
    const { onRef } = this.props
    const { isShowNoteField, remainChars, maxLength } = this.state

    return(
      isShowNoteField || !isEmpty(notes) ? (
        <div className="dlvr-note Hide-Border">
          <div className="Countdown-Remaining Default-TextArea-Placeholde w100">
            <div className={`Countdown-Remaining__Input flex ${isShowText ? 'Hide-Border-Bottom Radius-Top-Default' : 'Radius-default'}`}>
              <textarea
                autoFocus={isEmpty(notes)}
                name="document_returns_optional_notes"
                maxLength={maxLength}
                className="Resize-None flex flex-index Radius-default pt10 pr10 pb10 pl10 White-bg default-font h80px"
                placeholder={I18n.t('webapp.new_booking.step_2.optional_notes_to_driver')}
                ref={ref => onRef(ref, 'notes')}
                defaultValue={notes}
                onChange={e => this.handleChangeNotes(e)}
              />
            </div>
            {isShowText ? (
              <p className="default-color small-font center Line-bg Radius-Bottom-Default default-color-icon Countdown-Remaining__Info reset m pt5 pl10 pr10 pb5">
                {`${remainChars} ${I18n.t('webapp.label.characters_remaining')}`}
              </p>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="flex flex-start pl5 pr5 cur-pointer w-fit-content" onClick={() => this.setState({ isShowNoteField: true })}>
          <img src={OPTIONAL_NOTE} alt="optional_note" />
          <b className="ml10 default-font White-text">
            {I18n.t('webapp.new_booking.step_2.optional_notes_to_driver')}
          </b>
        </div>
      )
    )
  }

  renderAddress = (invalidKeys) => {
    const {
      extraInfos,
      isDefaultView,
      address,
      handleAddress,
      handleAddressKeyDown,
      verifyEnableDocument,
    } = this.props
    const { errorMessage } = this.state
    return (
      <div className="mar10 relative">
        {!isDefaultView && (
          <label className="capitalize mar10 ml5 pt5 block White-text default-font">
            {I18n.t('contacts.labels.address_detail')}
          </label>
        )}
        <AddressInput
          ref={(ref) => {
            this.inneraddress_1 = ref?.textarea
          }}
          className={clsx(
            !verifyEnableDocument && 'Input-Disabled',
            CPOD.validErrorKeys(invalidKeys, 'address_1') && 'select-error',
            'ml5 mr5 w-auto'
          )}
          id="booking_locations_attributes_cpod"
          name="booking[locations_attributes][cpod]"
          defaultValue={address}
          placeholder={I18n.t('contacts.labels.address')}
          disabled={!verifyEnableDocument}
          extraInfos={extraInfos}
          onClickPredictLocation={place => this.handleSetPlace(place)}
          onChange={value => handleAddress(value)}
          onKeyDown={e => handleAddressKeyDown(e)}
          onClick={() => eventTracking.selectLocationField('document return')}
          clickShowMore={value => eventTracking.getGoogleLocationsResults('document return', value)}
        />
        {errorMessage && (
          <div className="red ml5 mt5 mar10">
            <span class="message-error icon-error red default-small-font">
              {errorMessage}
            </span>
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      containerClassName,
      currentCustomer,
      documentReturn,
      extraInfos,
      renderActions,
      isDefaultView,
      handleAddMe,
      contacts,
      personalContacts,
      phoneNumber,
      address,
      stepActions,
      contactActions,
      onRef,
      verifyEnableDocument,
      isScrollPopup,
      popupHeight,
      booking,
    } = this.props
    let documentReturnData = {}
    const { remainChars, maxLength } = this.state
    const { invalidKeys = [] } = documentReturn || {}
    const disabledAddressAutoFill = extraInfos.enable_grey_out_on_document_return || CPOD.disabledAddressAutoFill(address, verifyEnableDocument) ? 'Input-Disabled' : ''
    const isShowText = remainChars <= Math.round(
      maxLength * (extraInfos.remaining_characters_in_note_fields / 100)
    )
    const countryCode = extraInfos.country_code?.toLowerCase()
    booking?.useDataAgain && booking?.booking_tracking && !booking.documentReturn
      ? documentReturnData = booking.booking_tracking
      : documentReturnData = documentReturn
    return (
      <div className={containerClassName} style={isScrollPopup ? { height: popupHeight } : {}}>
        {/* {Title} */}
        {isDefaultView && CPOD.renderTitle(currentCustomer.id)}

        {currentCustomer.id && verifyEnableDocument ? (
          <div className={`Popover-Item actions ${!isDefaultView ? 'reset pt-imp' : ''}`}>
            <div className="flex-index mr5 ml5">
              <button
                type="button"
                onClick={() => handleAddMe()}
                className="white-text white-border with-icon Button w100"
                disabled={!currentCustomer.id}
              >
                {I18n.t('contacts.labels.add_me')}
              </button>
            </div>
            <MyLocations
              currentCustomer={currentCustomer}
              extraInfos={extraInfos}
              stepActions={stepActions}
              contactActions={contactActions}
              buttonText={I18n.t('contacts.labels.add_from_contact')}
              emptyMessage={I18n.t('booking_details.notify.no_contacts')}
              actionContactText={I18n.t('contacts.labels.set_location')}
              handleSelectContact={({ contact }) => this.handleSetLocationFromContact(contact)}
              contacts={contacts}
              personalContacts={personalContacts}
              className="Popover-Item Selectbox-Contact Selectbox-Contact-Customs active block-imp mr5"
              locationContactID={documentReturnData.contactID}
              onRef={(ref) => { this.childMyLocations = ref }}
            />
          </div>
        ) : <div className={isDefaultView ? 'pt15 mar5' : ''} />}

        {!isDefaultView && <div className={currentCustomer.id && verifyEnableDocument ? 'pt15' : ''} />}

        {/* {Form} */}
        <div className={`${(isDefaultView) ? 'mar15' : ''} flex-index`}>
          <div className="mar10">
            {!isDefaultView && (
              <label className="capitalize mar10 ml5 pt5 block White-text default-font">
                {I18n.t('contacts.labels.contact_name')}
              </label>
            )}
            <div className={`Input ${!verifyEnableDocument ? 'Input-Disabled' : ''} ${CPOD.validErrorKeys(invalidKeys, 'recipient_name') ? 'select-error' : ''} pr10 pl10 ml5 mr5`}>
              <input
                className="default-font w100 reset p"
                ref={ref => onRef(ref, 'recipient_name')}
                name="document_returns_recipient_name"
                placeholder={I18n.t('webapp.new_booking.step_2.recipient_name')}
                defaultValue={documentReturnData.recipient_name}
                disabled={!verifyEnableDocument}
                tabIndex={1}
                onChange={e => this.handleChangePicName(e)}
              />
            </div>
          </div>
          <div className="mar10 location-number">
            {!isDefaultView && (
              <label className="capitalize mar10 ml5 pt5 block White-text default-font">
                {I18n.t('contacts.labels.contact_phone')}
              </label>
            )}
            <InputPhoneNumber
              isWebApp
              disabled={!verifyEnableDocument}
              customRef={ref => this.handleRef(ref, 'recipient_phone')}
              closestElement={`location-number pr10 pl10 ml5 mr5 ${CPOD.validErrorKeys(invalidKeys, 'recipient_phone') ? 'select-error' : ''}`}
              telInputProps={{ tabIndex: 1 }}
              phoneNumber={phoneNumber}
              countryCode={countryCode}
              fieldId="booking_locations_attributes_cpod_phone"
              phoneField="booking[locations_attributes][cpod_phone]"
              onPhoneChange={this.handleCustomerPhoneChange}
              onPhoneBlur={this.handleCustomerPhoneBlur}
              onPhoneFocus={this.handleCustomerPhoneFocus}
            />
          </div>
          {this.renderAddress(invalidKeys)}
          <div className="mar10">
            <div className={`Input ${CPOD.validErrorKeys(invalidKeys, 'city') ? 'select-error' : ''} pr10 pl10 ml5 mr5 ${disabledAddressAutoFill}`}>
              <input
                tabIndex={1}
                className="default-font w100 reset p"
                type="text"
                ref={ref => onRef(ref, 'city')}
                name="document_returns_city"
                placeholder={I18n.t('webapp.new_booking.step_2.city')}
                defaultValue={documentReturnData.city}
                onChange={e => this.handleChangeMappingField(e.target.value, 'administrative_area_level_2')}
              />
            </div>
          </div>
          {this.renderStateAndPostalCode(documentReturnData, invalidKeys, disabledAddressAutoFill)}
          {this.renderNoteField(documentReturnData.notes, isShowText)}
        </div>

        {/* {Actions} */}
        {!isUndefined(renderActions) && size(renderActions().props.children) && renderActions()}
      </div>
    )
  }
}

CPOD.propTypes = {
  area: PropTypes.shape({}),
  address: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  currentCustomer: PropTypes.shape({}),
  containerClassName: PropTypes.string,
  contacts: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  contactActions: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ]),
  documentReturn: PropTypes.shape({}),
  booking: PropTypes.shape(undefined),
  extraInfos: PropTypes.shape({}),
  handleAddMe: PropTypes.func,
  handleAddress: PropTypes.func,
  handleAddressKeyDown: PropTypes.func,
  handleMappingAddress: PropTypes.func,
  handlePhoneChange: PropTypes.func,
  handleSelectContact: PropTypes.func,
  handleChangeMappingField: PropTypes.func,
  isDefaultView: PropTypes.bool,
  personalContacts: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  phoneNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onRef: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ]),
  renderActions: PropTypes.func,
  stepActions: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ]),
  verifyEnableDocument: PropTypes.bool,
  setLocation: PropTypes.shape({}),
  handleUpdateAddressComponentForDocumentReturn: PropTypes.shape({}),
  isScrollPopup: PropTypes.bool,
  popupHeight: PropTypes.number,
}

CPOD.defaultProps = {
  area: {},
  address: undefined,
  currentCustomer: {},
  containerClassName: '',
  contacts: {},
  contactActions: undefined,
  documentReturn: {
    recipient_name: '',
    recipient_phone: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    postal_code: '',
    latitude: null,
    longitude: null,
    notes: '',

    // local keys
    is_show: false,
    is_required: false,
    invalidKeys: [],
  },
  extraInfos: {},
  handleAddMe: undefined,
  handleAddress: undefined,
  handleAddressKeyDown: () => {},
  handleMappingAddress: undefined,
  handlePhoneChange: undefined,
  handleSelectContact: undefined,
  handleChangeMappingField: undefined,
  isDefaultView: false,
  personalContacts: {},
  phoneNumber: undefined,
  onRef: undefined,
  renderActions: undefined,
  stepActions: undefined,
  verifyEnableDocument: false,
  setLocation: {},
  handleUpdateAddressComponentForDocumentReturn: null,
  isScrollPopup: false,
  popupHeight: 0,
}

export default CPOD
