import { FULL_DAY } from 'constants/bookingConstants'
import {
  ANOTHER_IDEA,
  APPLY_ALL_FOR_ENTIRE_BATCH_POPUP,
  BOOKING_TIME_TYPE_POPUP,
  CUSTOM_REIMBURSEMENT,
  EXTRA_REQUIREMENTS,
  PICKUP_DATE_TIME,
  PICKUP_TIME_POPUP,
  SUB_ACCOUNT_TAG,
  SUB_POPUP_WHEN_DATA_IS_CHANGED,
  TIME_TYPE_OPTION,
  VEHICLE_TYPE,
  VEHICLE_TYPE_POPUP,
} from 'constants/ezSpreadSheetConstants'
import { assign, filter, forEach, isEmpty, map, size } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { calculate } from 'store/actions/ez_spread_sheet/batchActionCreators'
import { useAppDispatch, useAppSelector } from 'store/store'
import { batchActionsCreator } from 'store/toolkit/batch/batch.reducer'
import { batchTemplateActionsCreator } from 'store/toolkit/batchTemplate/batchTemplate.reducer'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { subCurrentPopupIDActionsCreator } from 'store/toolkit/subCurrentPopupID/subCurrentPopupID.reducer'
import Utils from 'utils/Utils'
import CPODUtils from 'utils/booking/CPODUtils'
import { checkErrorDistanceTable, getParamsForCalBatchEZ, getValidLocationForBooking } from 'utils/booking/common'
import {
  applyCustomReimbursementToAllSameVehicleBookings,
  applyExtraServiceToAllSameVehicleBookbings,
  applyExtraServiceToUndefinedSameVehicleBookbings,
  buildBadgeExtraService,
  buildImpactAllBookingsEmptyVehicleType,
  buildImpactAllBookingsVehicleType,
  buildImpactBookingVehicleTypeFromSetting,
} from 'utils/ez_spread_sheet/bookingExtraServices'
import {
  addNewEmptyBookingLocation,
  changeBookingNewEmptyRequire,
  checkReimbursentSetting,
} from 'utils/ez_spread_sheet/buildBookings'
import {
  bookingsHaveInvalidDataInFullDayTimeType,
  rightPickUpTimeForTimeType,
  vehicleTypeSettings,
  verifyPickUpTimeWhenChangeTimeType,
  verifyPickupTimeWhenChangeVehicle,
  verifyTimeTypeWhenChangeVehicle,
} from 'utils/ez_spread_sheet/stepValidates/verifyLogicWhenChangeVehicle'
interface IApplyForEntireBatch {
  tempSelectedValue?: any
  checkUseSubAccount?: any
  selectedSubAccount?: any
  confirmType: any
  pickupTime?: any
}
const ApplyForEntireBatch: React.FC<IApplyForEntireBatch> = ({
  tempSelectedValue,
  checkUseSubAccount,
  selectedSubAccount,
  confirmType,
  pickupTime,
}) => {
  const batch = useAppSelector((s) => s.batch)
  const batchTemplate = useAppSelector((s) => s.batchTemplate)
  const selectedBooking = useAppSelector((s) => s.selectedBooking)
  const extraServices = useAppSelector((s) => s.extraServices)
  const extraInfos = useAppSelector((s) => s.extraInfos)
  const subCurrentPopupID = useAppSelector((s) => s.subCurrentPopupID)
  const currentCustomer = useAppSelector((s) => s.currentCustomer)

  const [hasAnotherIdea, setHasAnotherIdea] = useState(false)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  function closePopup(goBack = false) {
    updateBatchTemplateBaseOnPopupType(goBack)
    dispatch(subCurrentPopupIDActionsCreator.updateSubCurrentPopupID(''))
    if (confirmType === EXTRA_REQUIREMENTS) {
      Utils.showToastrMessage('success', t('batches.messages.update_extra_success'))
    }
    if (confirmType === CUSTOM_REIMBURSEMENT) {
      Utils.showToastrMessage('success', t('batches.messages.update_custom_success'))
    }
  }

  async function buildImpactVehicleEntireBatch(forAllBks: any) {
    const params = {
      extraServices,
      currentCustomer,
      extraInfos,
    }
    let bookings = [...batch.bookings]
    bookings = forAllBks
      ? buildImpactAllBookingsVehicleType(bookings, selectedBooking, params, bookings)
      : buildImpactAllBookingsEmptyVehicleType(bookings, selectedBooking, params, bookings)
    Promise.resolve(dispatch(batchActionsCreator.updateBatchBookings({ bookings }))).then(() => {
      if (size(bookings)) {
        forEach(bookings, (booking) => {
          const res = CPODUtils.verifyDataBatchBookings(booking)
          CPODUtils.calculateCheckLocations({
            booking: res,
          })
        })
      }
    })
  }

  function updateAllBookingsPickupDateTime(forAllBks: any, pickupTime: any) {
    const bookings = batch.bookings.map((booking: any) => {
      const bookingClone = { ...booking, verify: { ...booking.verify } }
      if ((!forAllBks && bookingClone[confirmType]?.toString() === '') || forAllBks) {
        bookingClone['pickup_date_time'] = pickupTime
        bookingClone.verify.pickupTime = true
      }
      return checkReimbursentSetting(bookingClone, { currentCustomer })
    })
    Promise.resolve(() => {
      dispatch(batchActionsCreator.updateBatchBookings({ bookings }))
      dispatch(batchTemplateActionsCreator.updateBatchTemplate({ same_pickup_datetime: false }))
    }).then(() => {
      if (size(bookings)) {
        forEach(bookings, (booking) => {
          const res = CPODUtils.verifyDataBatchBookings(booking)
          CPODUtils.calculateCheckLocations({
            booking: res,
          })
        })
      }
    })
  }

  function updateExtraRequirementForEntireBatch(forAllBks: any) {
    const newBatch = forAllBks
      ? applyExtraServiceToAllSameVehicleBookbings(batch, batchTemplate, tempSelectedValue)
      : applyExtraServiceToUndefinedSameVehicleBookbings(batch, batchTemplate, tempSelectedValue)

    Promise.resolve(dispatch(batchActionsCreator.updateBatch({ attrs: newBatch }))).then(() => {
      if (size(newBatch?.bookings)) {
        forEach(newBatch?.bookings, (booking) => {
          const res = CPODUtils.verifyDataBatchBookings(booking)
          CPODUtils.calculateCheckLocations({
            booking: res,
          })
        })
      }
    })
  }

  function updateCustomReimbursementForEntireBatch() {
    const newBatch = applyCustomReimbursementToAllSameVehicleBookings(batch, batchTemplate, tempSelectedValue)
    Promise.resolve(dispatch(batchActionsCreator.updateBatch({ attrs: newBatch }))).then(() => {
      if (size(newBatch?.bookings)) {
        forEach(newBatch?.bookings, (booking) => {
          const res = CPODUtils.verifyDataBatchBookings(booking)
          CPODUtils.calculateCheckLocations({
            booking: res,
          })
        })
      }
    })
  }

  function updateSubAccountTagForEntireBatch(forAllBks: any) {
    const bookings = batch.bookings.map((booking: any) => {
      const bookingClone = { ...booking }
      if ((!forAllBks && bookingClone.popupType?.toString() === '') || forAllBks) {
        bookingClone.sub_account_tag = checkUseSubAccount ? selectedSubAccount : null
      }
      return bookingClone
    })
    Promise.resolve(dispatch(batchActionsCreator.updateBatchBookings({ bookings }))).then(() => {
      if (size(bookings)) {
        forEach(bookings, (booking) => {
          const res = CPODUtils.verifyDataBatchBookings(booking)
          CPODUtils.calculateCheckLocations({
            booking: res,
          })
        })
      }
    })
  }

  function updateTimeTypeForEntireBatch(forAllBks: any) {
    const timeTypeOpt = tempSelectedValue
    const bookings = forAllBks
      ? batch.bookings
      : filter(
          batch.bookings,
          (booking) =>
            !booking.time_type_option ||
            size(booking.time_type_option) === 0 ||
            booking.temp_id === selectedBooking.temp_id
        )

    const newBookings = bookings.map((booking: any) => {
      let tempBooking = { ...booking, verify: { ...booking.verify } }
      tempBooking.time_type_option = timeTypeOpt
      tempBooking.verify.timeType = true
      tempBooking = buildBadgeExtraService(tempBooking, extraServices)
      tempBooking = changeBookingNewEmptyRequire(tempBooking)
      tempBooking = checkReimbursentSetting(tempBooking, { currentCustomer })
      if (!isEmpty(tempBooking.time_type_option) && tempBooking.time_type_option.type_key !== FULL_DAY) {
        if (size(booking.locations) === 1) {
          tempBooking = addNewEmptyBookingLocation(tempBooking)
        }
      }

      return tempBooking
    })
    dispatch(batchTemplateActionsCreator.updateBatchTemplate({ same_time_type: false }))

    return newBookings
  }

  function verifyAllBookingPickupTimeAndTimeType(settings: any) {
    const bookings = batch.bookings
    return bookings.every((booking: any) => {
      return verifyPickupTimeWhenChangeVehicle(booking, settings) && !verifyTimeTypeWhenChangeVehicle(booking, settings)
    })
  }

  function buildImpactTimeTypeForAllBookings(pickupTime: string, settings: any, bookings: any[]) {
    // let bookings = batch.bookings
    if (!bookings?.length) {
      return []
    }
    const params = {
      currentCustomer,
      extraServices,
      pickupTime,
      settings,
    }
    const updatedBookings = bookings.map((booking: any) => {
      let tempBooking = { ...booking, verify: { ...booking.verify } }
      const pickupVerify = verifyPickupTimeWhenChangeVehicle(tempBooking, settings)
      if (!pickupVerify) {
        tempBooking.pickup_date_time = pickupTime
        tempBooking.verify.pickupTime = true
      }
      tempBooking = buildImpactBookingVehicleTypeFromSetting(tempBooking, params)

      return tempBooking
    })

    return updatedBookings
  }

  function getBookingFromBatch(selectedBooking: { temp_id: any }) {
    return batch?.bookings.find((booking: { temp_id: any }) => booking.temp_id === selectedBooking.temp_id)
  }

  function hasDifferentAttrSelected() {
    const { attribute } = confirmType
    const { updatedBooking } = selectedBooking
    const { bookings } = batch.bookings
    for (let i = 0; i < size(bookings); i += 1) {
      const compareAttr = bookings[i][attribute]
      if (!isEmpty(compareAttr) && updatedBooking.temp_id !== bookings[i].temp_id) {
        if (size(compareAttr) > 0) {
          return true
        }
      }
    }
    return false
  }

  function hasAnyEmptyData() {
    const { attribute } = confirmType
    const { bookings } = batch.bookings
    for (let i = 0; i < size(bookings); i += 1) {
      const compareAttr = bookings[i][attribute]
      if (isEmpty(compareAttr)) return true
    }
    return false
  }

  function updateBatchTemplateBaseOnPopupType(goBack: boolean) {
    const { popupType } = confirmType
    if (popupType === VEHICLE_TYPE) {
      dispatch(batchTemplateActionsCreator.updateBatchTemplate({ same_vehicle_type: goBack }))
    } else if (popupType === PICKUP_DATE_TIME) {
      dispatch(batchTemplateActionsCreator.updateBatchTemplate({ same_pickup_datetime: goBack }))
    } else if (popupType === EXTRA_REQUIREMENTS) {
      dispatch(batchTemplateActionsCreator.updateBatchTemplate({ same_extra_requirements: goBack }))
    } else if (popupType === CUSTOM_REIMBURSEMENT) {
      dispatch(batchTemplateActionsCreator.updateBatchTemplate({ same_custom_reimbursements: goBack }))
    } else if (popupType === SUB_ACCOUNT_TAG) {
      dispatch(batchTemplateActionsCreator.updateBatchTemplate({ same_sub_account_tag: goBack }))
    } else {
      dispatch(batchTemplateActionsCreator.updateBatchTemplate({ same_time_type: goBack }))
    }
  }

  async function applyForAllBookings(forAllBks: boolean) {
    const popupType = confirmType
    const booking = getBookingFromBatch(selectedBooking)
    const settings = vehicleTypeSettings(booking.vehicle_type)
    if (popupType === VEHICLE_TYPE) {
      buildImpactVehicleEntireBatch(forAllBks)
      if (!verifyAllBookingPickupTimeAndTimeType(settings)) {
        dispatch(subCurrentPopupIDActionsCreator.updateSubCurrentPopupID(PICKUP_TIME_POPUP))
      }
      if (size(bookingsHaveInvalidDataInFullDayTimeType(batch, extraServices.vehicleTypes)) > 0) {
        dispatch(currentPopupIDActionsCreator.updateCurrentPopupID(VEHICLE_TYPE_POPUP))
        dispatch(subCurrentPopupIDActionsCreator.updateSubCurrentPopupID(SUB_POPUP_WHEN_DATA_IS_CHANGED))
      } else {
        closePopup()
        dispatch(currentPopupIDActionsCreator.updateCurrentPopupID(''))
      }
    }

    if (popupType === PICKUP_DATE_TIME) {
      updateAllBookingsPickupDateTime(forAllBks, pickupTime)
      closePopup()
      dispatch(currentPopupIDActionsCreator.updateCurrentPopupID(''))
    }

    if (popupType === EXTRA_REQUIREMENTS) {
      updateExtraRequirementForEntireBatch(forAllBks)
      closePopup()
      dispatch(currentPopupIDActionsCreator.updateCurrentPopupID(''))
    }

    if (popupType === CUSTOM_REIMBURSEMENT) {
      updateCustomReimbursementForEntireBatch()
      closePopup()
      dispatch(currentPopupIDActionsCreator.updateCurrentPopupID(''))
    }

    if (popupType === TIME_TYPE_OPTION) {
      let updatedBookings = updateTimeTypeForEntireBatch(forAllBks)
      if (forAllBks === false && verifyPickUpTimeWhenChangeTimeType(booking, settings)) {
        closePopup()
      } else if (!isEmpty(tempSelectedValue)) {
        const isFullDay = tempSelectedValue.type_key === FULL_DAY
        const pickupTime = moment(rightPickUpTimeForTimeType(settings, isFullDay)).format()
        updatedBookings = buildImpactTimeTypeForAllBookings(pickupTime, settings, updatedBookings)
        closePopup()
        dispatch(currentPopupIDActionsCreator.updateCurrentPopupID(''))
      }
      const newBooking = map(updatedBookings, (item) => getValidLocationForBooking(item))
      const bookingParams = getParamsForCalBatchEZ(currentCustomer, newBooking, extraInfos)
      const response = await calculate(bookingParams, newBooking)
      if (response && response.bookings && !isEmpty(response.bookings.data)) {
        const { bookings: bookingsResponse } = response
        const { data } = bookingsResponse
        const { finalBooking, isValid } = checkErrorDistanceTable(data, updatedBookings)
        if (isValid) {
          Promise.resolve(dispatch(batchActionsCreator.updateBatchBookings({ bookings: updatedBookings }))).then(() => {
            if (size(updatedBookings)) {
              forEach(updatedBookings, async (getBooking) => {
                const res = CPODUtils.verifyDataBatchBookings(getBooking)
                await CPODUtils.updateTimeTypeBaseOnLocation({
                  booking: res,
                })
              })
            }
          })
        } else {
          const newBatch = assign({}, batch, { bookings: finalBooking })
          dispatch(batchActionsCreator.updateBatch({ attrs: newBatch }))
        }
      }
      return true
    }

    if (popupType === SUB_ACCOUNT_TAG) {
      updateSubAccountTagForEntireBatch(forAllBks)
      closePopup()
      dispatch(currentPopupIDActionsCreator.updateCurrentPopupID(''))
    }
  }

  function applyForEntireBatchAfterChangePickupTime(forAllBks: boolean) {
    const booking = getBookingFromBatch(selectedBooking)
    const settings = vehicleTypeSettings(booking.vehicle_type)
    if (verifyPickupTimeWhenChangeVehicle(booking, settings)) {
      dispatch(currentPopupIDActionsCreator.updateCurrentPopupID(''))
      closePopup()
    } else {
      if (size(settings) > 0) {
        buildImpactVehicleEntireBatch(forAllBks)
      }
      dispatch(currentPopupIDActionsCreator.updateCurrentPopupID(APPLY_ALL_FOR_ENTIRE_BATCH_POPUP))
      dispatch(subCurrentPopupIDActionsCreator.updateSubCurrentPopupID(PICKUP_TIME_POPUP))
    }
  }

  function renderContentOfPickupTimeFollowVehicleLogic() {
    return (
      <div className="Box">
        <div className="Modal-Head Box-Head">{t('batches.mapping_key.vehicle_type')}</div>
        <div className="Modal-Content Box-Content">
          <div
            className="Modal-SplitView Modal-SplitView-Default-Font"
            dangerouslySetInnerHTML={{ __html: t('batches.messages.vehicle_pickuptime_popup') }}
          />
        </div>
        <div className="Modal-Actions Box-Actions Box-Actions-Customs">
          <div className="Modal-Actions-Right">
            <button
              type="button"
              className="green Button flex-index"
              onClick={() => applyForEntireBatchAfterChangePickupTime(true)}
            >
              {t('batches.button.ok_option')}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function closePopupWithoutChangingData() {
    dispatch(subCurrentPopupIDActionsCreator.updateSubCurrentPopupID(''))
  }

  function renderTimeTypeContentFollowVehicleLogic() {
    const settings = selectedBooking.vehicle_type?.settings || {}
    let showConfirmBtn = true
    if (!isEmpty(settings)) {
      if (!settings?.schedule_enabled && !settings?.full_day_enabled) {
        showConfirmBtn = false
      }
    }
    return (
      <div className="Box">
        <div className="Modal-Head Box-Head">{t('batches.mapping_key.vehicle_type')}</div>
        <div className="Modal-Content Box-Content">
          <div
            className="Modal-SplitView Modal-SplitView-Default-Font"
            dangerouslySetInnerHTML={{ __html: t('batches.messages.vehicle_timetype_popup') }}
          />
        </div>
        <div className="Modal-Actions Box-Actions Box-Actions-Customs">
          <div className="Modal-Actions-Right Modal-Actions Box-Actions w100">
            <button
              type="button"
              className="close-modal gray Button Button-Default"
              onClick={() => closePopupWithoutChangingData()}
            >
              {t('batches.button.go_back')}
            </button>
            {showConfirmBtn && (
              <button type="button" className="green Button Button-Default" onClick={() => changeToTimeTypePopups()}>
                {t('batches.button.ok_option')}
              </button>
            )}
          </div>
        </div>
      </div>
    )
  }

  function renderRightTextForEachPopupType(popupType?: string) {
    if (confirmType === VEHICLE_TYPE) {
      const vehicleType = selectedBooking.vehicle_type
      const typeName = vehicleType.name || ''
      if (popupType === ANOTHER_IDEA) {
        return t('batches.messages.vehicle_another_idea_content', { type_name: typeName })
      }
      return t('batches.messages.apply_all_vehicle_type_content', { type_name: typeName })
    }
    if (confirmType === PICKUP_DATE_TIME) {
      if (popupType === ANOTHER_IDEA) {
        return t('batches.messages.pickup_time_another_idea_content')
      }
      return t('batches.messages.apply_all_pickup_time_content')
    }
    if (confirmType === TIME_TYPE_OPTION) {
      const timeType = selectedBooking.time_type_option
      const typeName = timeType ? t(`batches.mapping_key.${timeType.type_key}`) : ''
      if (popupType === ANOTHER_IDEA) {
        return t('batches.messages.time_type_another_idea_content', { type_name: typeName })
      }
      return t('batches.messages.apply_all_time_type_content', { type_name: typeName })
    }
    if (popupType === ANOTHER_IDEA) {
      return t('batches.messages.extra_service_another_idea_content')
    }
    if (confirmType === CUSTOM_REIMBURSEMENT) {
      return t('batches.messages.apply_all_custom_reimbursement_content')
    }
    if (confirmType === SUB_ACCOUNT_TAG) {
      return t('batches.messages.apply_all_sub_account_tag', {
        tag_name: checkUseSubAccount
          ? selectedSubAccount && selectedSubAccount.sub_account_name
          : t('batches.messages.no_tag'),
      })
    }
    return t('batches.messages.apply_all_extra_service_content')
  }

  function handleAnotherIdea(hasAnotherIdea: boolean) {
    setHasAnotherIdea(hasAnotherIdea)
    if (!hasAnotherIdea) {
      dispatch(subCurrentPopupIDActionsCreator.updateSubCurrentPopupID(''))
    }
  }

  function renderNormalPopup(showThirdBtn: boolean) {
    const textContent = renderRightTextForEachPopupType()
    return (
      <div className="Box">
        <div className="Modal-Head Box-Head">{t('batches.label.confirm_title')}</div>
        <div className="Modal-Content Box-Content">
          <div className="Modal-SplitView Modal-SplitView-Default-Font">{textContent}</div>
        </div>
        <div className="Modal-Actions Box-Actions Box-Actions-Customs">
          <div className="Modal-Actions-Right">
            <button
              type="button"
              className={`close-modal gray Button-Default Button ${showThirdBtn ? 'flex-index' : ''}`}
              onClick={() => closePopup(true)}
            >
              {t('batches.button.go_back')}
            </button>
            {showThirdBtn && (
              <button
                type="button"
                className="green-text green-border Button Button-Default flex-index"
                onClick={() => handleAnotherIdea(true)}
              >
                {t('batches.button.another_idea')}
              </button>
            )}
            <button
              type="button"
              className={`green Button Button-Default ${showThirdBtn ? 'flex-index' : ''}`}
              onClick={() => applyForAllBookings(true)}
            >
              {t('webapp.action.proceed')}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function anotherIdeaPopup() {
    const textContent = renderRightTextForEachPopupType(ANOTHER_IDEA)
    return (
      <div className="Box">
        <div className="Modal-Head Box-Head">{t('batches.label.confirm_title')}</div>
        <div className="Modal-Content Box-Content">
          <div className="Modal-SplitView Modal-SplitView-Default-Font">{textContent}</div>
        </div>
        <div className="Modal-Actions Box-Actions Box-Actions-Customs">
          <div className="Modal-Actions-Right">
            <button className="close-modal Button-Default gray Button" onClick={() => handleAnotherIdea(false)}>
              {t('batches.button.go_back')}
            </button>
            <button className="green Button Button-Default" onClick={() => applyForAllBookings(false)}>
              {t('webapp.action.proceed')}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function changeToTimeTypePopups() {
    buildImpactVehicleEntireBatch(true)
    document.getElementById('batch-update-vehicle-type')?.classList?.add('visible')
    dispatch(subCurrentPopupIDActionsCreator.updateSubCurrentPopupID(BOOKING_TIME_TYPE_POPUP))
  }

  function verifyTimeType(settings: any, timeTypeOpt: any) {
    if (size(settings) === 0 || !timeTypeOpt) {
      return true
    }
    const bookings = batch.bookings
    for (let i = 0; i < size(bookings); i += 1) {
      const verify = verifyTimeTypeWhenChangeVehicle(bookings[i], settings)
      if (!verify) {
        return false
      }
    }
    return true
  }

  if (subCurrentPopupID !== APPLY_ALL_FOR_ENTIRE_BATCH_POPUP) {
    return <span key={`empty-apply-vehicle-${APPLY_ALL_FOR_ENTIRE_BATCH_POPUP}`} />
  }
  const showThirdBtn = hasDifferentAttrSelected() && hasAnyEmptyData()
  let currentTemplate = renderNormalPopup(showThirdBtn)
  const settings = vehicleTypeSettings(selectedBooking.vehicle_type)
  const timeTypeOpt = selectedBooking.time_type_option
  if (confirmType === VEHICLE_TYPE) {
    if (!verifyTimeType(settings, timeTypeOpt)) {
      currentTemplate = renderTimeTypeContentFollowVehicleLogic()
    } else if (!verifyPickupTimeWhenChangeVehicle(selectedBooking, settings)) {
      currentTemplate = renderContentOfPickupTimeFollowVehicleLogic()
    }
  }
  if (hasAnotherIdea) {
    currentTemplate = anotherIdeaPopup()
  }
  return (
    <div className="vertical-scroll Modal Modal-Basic visible" id={APPLY_ALL_FOR_ENTIRE_BATCH_POPUP}>
      {currentTemplate}
    </div>
  )
}

export default ApplyForEntireBatch
